import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { apiService } from '@/services/api-services'
import { PostStore } from '@/stores/post-store'
import { action, computed, IReactionDisposer, observable, reaction, when } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { shuffle } from 'lodash'
import { Subject } from 'rxjs'
import { ReviewCategory } from '@/models/post-model'
export class ReviewViewModel {
  private _disposers: IReactionDisposer[]
  private _unsubcrible = new Subject()

  constructor() {
    this.fetchReviewCategories()
    this.fetchTrendingReviews()
    this.fetchReviews()

    this._disposers = [
      reaction(
        () => this.filter,
        (filter) => {
          if (filter) {
            this.fetchReviews()
            this.filterState = { _limit: 9, _start: 0 }
          }
        }
      ),
    ]
  }
  /**
   * TRENDING SECTION
   */
  @observable trendingReviews: PostStore[] = []
  @observable trendingReviewFetching = false
  @observable trendingReviewsDisplayed: PostStore[] = []

  /**
   * REVIEW SECTION
   */
  @observable categories: ReviewCategory[] = []
  @observable selectedCategory?: ReviewCategory = undefined
  @observable types = ['Latest', 'Top']
  @observable selectedType = this.types[0]
  @observable filter = { _limit: 9, _start: 0, _sort: 'createdAt:desc', type: 'review' }
  @observable filterState = { _limit: 9, _start: 0 }
  @observable loadingMore = false

  @action.bound onCategoryChange(category: string) {
    when(() => !!this.categories.length)
    const selectedCategory = this.categories.find((item) => item.name === category)
    if (selectedCategory) {
      this.selectedCategory = selectedCategory
      if (selectedCategory.name !== 'All') {
        const filter = {
          _limit: 9,
          _start: 0,
          _sort: this.filter._sort,
          type: 'review',
          reviewCategories: selectedCategory.id,
        }
        this.changeFilter(filter)
      } else {
        const filter = { _limit: 9, _start: 0, _sort: this.filter._sort, type: 'review' }
        this.changeFilter(filter)
      }
    }
  }

  @action.bound onTypeChange(type: string) {
    this.selectedType = type
    if(this.selectedType === 'Latest') {
      const filter = {...this.filter, _sort: 'createdAt:desc', _start: 0, _limit: 9}
      this.changeFilter(filter)
    } else if(this.selectedType === 'Top') {
      const filter = {...this.filter, _sort: 'topScore:desc', _start: 0, _limit: 9}
      this.changeFilter(filter)
    }
  }

  @action.bound changeFilter(filter) {
    this.filter = filter
  }

  @observable reviews: PostStore[] = []
  @observable reviewFetching = false
  @observable total = 0;

  @asyncAction *fetchReviewCategories() {
    try {
      const reviewCategories = yield apiService.reviewCategories.find({ _start: 0, _limit: -1 })
      this.categories = [{ name: 'All' }, ...reviewCategories]
      this.selectedCategory = this.categories.find((item) => item.name === 'All')
    } catch (error) {}
  }

  @asyncAction *fetchTrendingReviews() {
    try {
      this.trendingReviewFetching = true
      const trendingReviews = yield apiService.posts.fetchPosts({ _limit: 20, type: 'review', _sort: 'score:desc' })
      this.trendingReviews = trendingReviews.map((item) => new PostStore(item))
      this.trendingReviewsDisplayed = this.trendingReviews.slice(0, 2)
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.trendingReviewFetching = false
    }
  }

  @asyncAction *fetchReviews() {
    try {
      this.reviewFetching = true
      const reviews = yield apiService.posts.fetchPosts(this.filter)
      if (reviews) {
        this.reviews = reviews.map((item) => new PostStore(item))
        this.total = reviews[0]?.total || 0
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.reviewFetching = false
    }
  }

  @action.bound updateTrendingReviewsDisplayed() {
    const difference = this.trendingReviews.filter(
      (item) =>
        item.post.id !== this.trendingReviewsDisplayed[0].post.id &&
        item.post.id !== this.trendingReviewsDisplayed[1].post.id
    )
    this.trendingReviewsDisplayed = shuffle(difference).slice(0, 2)
  }

  @asyncAction *loadMore() {
    try {
      this.loadingMore = true
      this.filterState = { ...this.filter, _start: this.filterState._start + this.filterState._limit }
      const postMore = yield apiService.posts.fetchPosts({ ...this.filterState })
      const newPost = postMore.map((post) => new PostStore(post))
      const currentPost = this.reviews
      this.reviews = [...currentPost, ...newPost]
    } catch (error) {
    } finally {
      this.loadingMore = false
    }
  }

  @computed get reviewCanLoadMore() {
    if (this.reviews.length < this.total) return true
    else return false
  }

  @computed get reviewCategoryNames() {
    return this.categories.map((item) => item.name)
  }

  @computed get selectedCategoryName() {
    return this.selectedCategory?.name
  }
}
