








































































































































































































































import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { Observer } from 'mobx-vue'
import { Component, Provide, Vue } from 'vue-property-decorator'
import { ReviewViewModel } from '../viewmodels/review-viewmodel'

@Observer
@Component({
  components: {
    'owner-section': () => import('@/modules/common/components/section/owner-section.vue'),
    'my-dao-section': () => import('@/modules/common/components/section/my-dao-section.vue'),
    'most-active-user-section': () => import('@/modules/common/components/section/most-active-user-section.vue'),
    'most-active-dao-section': () => import('@/modules/common/components/section/most-active-dao-section.vue'),
    'trending-card': () => import('@/modules/community/home/components/review-components/trending-card.vue'),
    'review-card': () => import('@/modules/community/home/components/review-components/review-card.vue'),
    observer: () => import('vue-intersection-observer'),
  },
})
export default class Review extends Vue {
  @Provide() vm = new ReviewViewModel()
  createPostController = createPostController

  loadMore(entry) {
    if (this.vm.reviewCanLoadMore) {
      if (entry.isIntersecting) this.vm.loadMore()
    }
  }
}
